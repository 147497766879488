import { gql } from '@apollo/client';
import Fragments from 'services/graphql/fragments';

export default {
	uploadPublicFile: gql`
		mutation uploadPublicFile($dataURI: String!, $fileExt: String) {
			uploadPublicFile(dataURI: $dataURI, fileExt: $fileExt)
		}
	`,
	uploadNonSVGPublicFile: gql`
		mutation uploadNonSVGPublicFile($dataURI: String!, $fileExt: String) {
			uploadNonSVGPublicFile(dataURI: $dataURI, fileExt: $fileExt)
		}
	`,
	updatePodcastById: gql`
		mutation updatePodcastById($podcastId: UUID, $podcast: PodcastInput) {
			updatePodcastById(podcastId: $podcastId, podcast: $podcast) {
				...PodcastParts
			}
		}
		${Fragments.PodcastParts}
	`,
	deletePodcastById: gql`
		mutation deletePodcastById($podcastId: UUID) {
			deletePodcastById(podcastId: $podcastId)
		}
	`,
	createEpisode: gql`
		mutation createEpisode($accountId: Int, $episode: EpisodeInput) {
			createEpisode(accountId: $accountId, episode: $episode) {
				...EpisodeParts
			}
		}
		${Fragments.EpisodeParts}
	`,
	updateEpisodeById: gql`
		mutation updateEpisodeById(
			$accountId: Int
			$episodeId: UUID
			$episode: EpisodeInput
		) {
			updateEpisodeById(
				accountId: $accountId
				episodeId: $episodeId
				episode: $episode
			) {
				...EpisodeParts
			}
		}
		${Fragments.EpisodeParts}
	`,
	createClip: gql`
		mutation createClip($clip: ClipInput!) {
			createClip(clip: $clip) {
				...ClipParts
			}
		}
		${Fragments.ClipParts}
	`,
	updateClipById: gql`
		mutation updateClipById($clipId: UUID, $clip: ClipInput) {
			updateClipById(clipId: $clipId, clip: $clip) {
				...ClipParts
			}
		}
		${Fragments.ClipParts}
	`,
	generateClipSuggestions: gql`
		mutation generateClipSuggestions($episodeId: UUID) {
			generateClipSuggestions(episodeId: $episodeId)
		}
	`,
	deleteClipById: gql`
		mutation deleteClipById($clipId: UUID) {
			deleteClipById(clipId: $clipId)
		}
	`,
	createHostedPodcast: gql`
		mutation createHostedPodcast(
			$podcast: PodcastInput!
			$imageURI: String
			$collectionType: String
		) {
			createHostedPodcast(
				podcast: $podcast
				imageURI: $imageURI
				collectionType: $collectionType
			) {
				...PodcastParts
			}
		}
		${Fragments.PodcastParts}
	`,
	createPodcastFromRssFeedUrl: gql`
		mutation createPodcastFromRssFeedUrl($podcastRSS: PodcastRssInput!) {
			createPodcastFromRssFeedUrl(podcastRSS: $podcastRSS) {
				...PodcastParts
			}
		}
		${Fragments.PodcastParts}
	`,
	downloadEpisode: gql`
		mutation downloadEpisode(
			$hostedUrl: String
			$episodeId: UUID
			$accountId: Int
		) {
			downloadEpisode(
				hostedUrl: $hostedUrl
				accountId: $accountId
				episodeId: $episodeId
			) {
				...EpisodeParts
			}
		}
		${Fragments.EpisodeParts}
	`,
	publishEpisode: gql`
		mutation publishEpisode(
			$episodeId: UUID
			$setPrivate: Boolean
			$publishOn: DateTime
			$youtubeVisibility: String
		) {
			publishEpisode(
				episodeId: $episodeId
				setPrivate: $setPrivate
				publishOn: $publishOn
				youtubeVisibility: $youtubeVisibility
			) {
				__typename
				id
				status
				slug
				publicStatus
			}
		}
	`,
	unPublishEpisode: gql`
		mutation unPublishEpisode(
			$episodeId: UUID
			$slug: String
			$setPrivate: Boolean
			$jobId: BigInt
		) {
			unPublishEpisode(
				episodeId: $episodeId
				slug: $slug
				setPrivate: $setPrivate
				jobId: $jobId
			) {
				__typename
				id
				status
				slug
				publicStatus
			}
		}
	`,
	deleteEpisodeById: gql`
		mutation deleteEpisodeById($episodeId: UUID) {
			deleteEpisodeById(episodeId: $episodeId)
		}
	`,
	deleteEpisodeByIdHard: gql`
		mutation deleteEpisodeByIdHard($episodeId: UUID) {
			deleteEpisodeByIdHard(episodeId: $episodeId)
		}
	`,
	saveModifiedTranscript: gql`
		mutation saveModifiedTranscript(
			$accountId: Int!
			$episodeId: UUID!
			$transcript: JSONObject
		) {
			saveModifiedTranscript(
				accountId: $accountId
				episodeId: $episodeId
				transcript: $transcript
			)
		}
	`,
	retryFailedTranscription: gql`
		mutation retryFailedTranscription($episodeId: UUID!, $accountId: Int) {
			retryFailedTranscription(episodeId: $episodeId, accountId: $accountId)
		}
	`,
	updateBrandSettings: gql`
		mutation updateBrandSettings(
			$accountId: Int!
			$brandSettings: JSONObject
			$logoUri: String
			$logoLink: String
		) {
			updateBrandSettings(
				accountId: $accountId
				brandSettings: $brandSettings
				logoUri: $logoUri
				logoLink: $logoLink
			) {
				...AccountParts
			}
		}
		${Fragments.AccountParts}
	`,
	createEmptyRelatedResource: gql`
		mutation createEmptyRelatedResource($episodeId: UUID!) {
			createEmptyRelatedResource(episodeId: $episodeId) {
				...ResourceParts
			}
		}
		${Fragments.ResourceParts}
	`,
	createRelatedResource: gql`
		mutation createRelatedResource(
			$episodeId: UUID!
			$resource: ResourceInput!
		) {
			createRelatedResource(episodeId: $episodeId, resource: $resource) {
				...ResourceParts
			}
		}
		${Fragments.ResourceParts}
	`,
	updateRelatedResource: gql`
		mutation updateRelatedResource($resource: ResourceInput!) {
			updateRelatedResource(resource: $resource) {
				...ResourceParts
			}
		}
		${Fragments.ResourceParts}
	`,
	deleteRelatedResource: gql`
		mutation deleteRelatedResource($resourceId: UUID!) {
			deleteRelatedResource(resourceId: $resourceId)
		}
	`,
	migratePodcastToHosted: gql`
		mutation migratePodcastToHosted(
			$podcastId: UUID!
			$externalId: String
			$feedUrl: String
		) {
			migratePodcastToHosted(
				podcastId: $podcastId
				externalId: $externalId
				feedUrl: $feedUrl
			) {
				...PodcastParts
			}
		}
		${Fragments.PodcastParts}
	`,
	publishEpisodeMedia: gql`
		mutation publishEpisodeMedia(
			$episodeId: UUID!
			$accountId: Int!
			$fileName: String
			$publishOnDate: Date
		) {
			publishEpisodeMedia(
				episodeId: $episodeId
				accountId: $accountId
				fileName: $fileName
				publishOnDate: $publishOnDate
			)
		}
	`,
	addTranscriptionCredits: gql`
		mutation addTranscriptionCredits($accountId: Int!, $amount: Int!) {
			addTranscriptionCredits(accountId: $accountId, amount: $amount) {
				...AccountParts
			}
		}
		${Fragments.AccountParts}
	`,
	startRevTranscription: gql`
		mutation createRevTranscriptionJob(
			$episodeId: UUID!
			$accountId: Int!
			$userId: Int!
		) {
			createRevTranscriptionJob(
				episodeId: $episodeId
				accountId: $accountId
				userId: $userId
			) {
				id
			}
		}
	`,
	processRawRevFile: gql`
		mutation processRawRevFile($episodeId: UUID!, $accountId: Int!) {
			processRawRevFile(episodeId: $episodeId, accountId: $accountId) {
				id
			}
		}
	`,
	createClipAudiogram: gql`
		mutation createClipAudiogram($options: AudiogramInput!) {
			createClipAudiogram(options: $options)
		}
	`,
	savePodcastIntegration: gql`
		mutation savePodcastIntegration(
			$podcastId: UUID!
			$integrationName: String!
			$integrationSettings: [IntegrationSettingInput!]!
		) {
			savePodcastIntegration(
				podcastId: $podcastId
				integrationName: $integrationName
				integrationSettings: $integrationSettings
			) {
				...IntegrationParts
			}
		}
		${Fragments.IntegrationParts}
	`,
	updateUser: gql`
		mutation updateUser(
			$userId: Int!
			$accountId: Int!
			$userSettings: UserSettingsInput!
		) {
			updateUser(
				userId: $userId
				accountId: $accountId
				userSettings: $userSettings
			)
		}
	`,
	createUser: gql`
		mutation createUser($accountId: Int!, $userSettings: UserSettingsInput!) {
			createUser(accountId: $accountId, userSettings: $userSettings)
		}
	`,
	disableAccountUser: gql`
		mutation disableAccountUser($userId: Int!, $accountId: Int!) {
			disableAccountUser(userId: $userId, accountId: $accountId)
		}
	`,
	getHubspotAuthUrl: gql`
		mutation getHubspotAuthUrl($podcastId: UUID!, $scopes: [JSONObject!]!) {
			getHubspotAuthUrl(podcastId: $podcastId, scopes: $scopes)
		}
	`,
	saveHubspotOAuthToken: gql`
		mutation saveHubspotOAuthToken($code: String!) {
			saveHubspotOAuthToken(code: $code) {
				...IntegrationParts
			}
		}
		${Fragments.IntegrationParts}
	`,
	deleteHubspotIntegration: gql`
		mutation deleteHubspotIntegration($integrationId: UUID!) {
			deleteHubspotIntegration(integrationId: $integrationId)
		}
	`,
	getYoutubeAuthUrl: gql`
		mutation getYoutubeAuthUrl($podcastId: UUID!) {
			getYoutubeAuthUrl(podcastId: $podcastId)
		}
	`,
	saveYoutubeOAuthToken: gql`
		mutation saveYoutubeOAuthToken($code: String!) {
			saveYoutubeOAuthToken(code: $code) {
				...IntegrationParts
			}
		}
		${Fragments.IntegrationParts}
	`,
	deleteYoutubeIntegration: gql`
		mutation deleteYoutubeIntegration($integrationId: UUID!) {
			deleteYoutubeIntegration(integrationId: $integrationId)
		}
	`,
	createApiKey: gql`
		mutation createApiKey(
			$accountId: Int!
			$name: String!
			$clientId: String!
			$clientSecret: String!
			$integrationName: String
			$isAccountLevel: Boolean
		) {
			createApiKey(
				accountId: $accountId
				name: $name
				clientId: $clientId
				clientSecret: $clientSecret
				integrationName: $integrationName
				isAccountLevel: $isAccountLevel
			) {
				...ApiKeyParts
			}
		}
		${Fragments.ApiKeyParts}
	`,
	updateApiKey: gql`
		mutation updateApiKey($id: Int!, $name: String!) {
			updateApiKey(id: $id, name: $name) {
				...ApiKeyParts
			}
		}
		${Fragments.ApiKeyParts}
	`,
	revokeApiKey: gql`
		mutation revokeApiKey($id: Int!) {
			revokeApiKey(id: $id)
		}
	`,
	processRssAudioFile: gql`
		mutation processRssAudioFile($accountId: Int!, $episodeId: UUID!) {
			processRssAudioFile(accountId: $accountId, episodeId: $episodeId)
		}
	`,
	saveAccountSaml: gql`
		mutation saveAccountSaml(
			$accountId: Int!
			$samlId: Int
			$samlSettings: AccountSamlInput!
		) {
			saveAccountSaml(
				accountId: $accountId
				samlId: $samlId
				samlSettings: $samlSettings
			) {
				...SamlParts
			}
		}
		${Fragments.SamlParts}
	`,
	deleteAccountSaml: gql`
		mutation deleteAccountSaml($samlId: Int) {
			deleteAccountSaml(samlId: $samlId)
		}
	`,
	createTheme: gql`
		mutation createTheme($accountId: Int!) {
			createTheme(accountId: $accountId) {
				...ThemeParts
			}
		}
		${Fragments.ThemeParts}
	`,
	deleteThemeById: gql`
		mutation deleteThemeById($themeId: UUID) {
			deleteThemeById(themeId: $themeId)
		}
	`,
	updateThemeById: gql`
		mutation updateThemeById($themeId: UUID, $theme: ThemeInput) {
			updateThemeById(themeId: $themeId, theme: $theme) {
				...ThemeParts
			}
		}
		${Fragments.ThemeParts}
	`,
	publishToYoutube: gql`
		mutation publishToYoutube(
			$accountId: Int!
			$episodeId: UUID!
			$youtubeEpisode: YoutubeInput
		) {
			publishToYoutube(
				accountId: $accountId
				episodeId: $episodeId
				youtubeEpisode: $youtubeEpisode
			)
		}
	`,
	createExternalObject: gql`
		mutation createExternalObject(
			$accountId: Int!
			$externalObject: ExternalObjectCreateInput!
		) {
			createExternalObject(
				accountId: $accountId
				externalObject: $externalObject
			) {
				...ExternalObjectParts
			}
		}
		${Fragments.ExternalObjectParts}
	`,
	updateExternalObjectById: gql`
		mutation updateExternalObjectById(
			$externalObjectId: UUID!
			$externalObject: ExternalObjectUpdateInput!
		) {
			updateExternalObjectById(
				externalObjectId: $externalObjectId
				externalObject: $externalObject
			) {
				...ExternalObjectParts
			}
		}
		${Fragments.ExternalObjectParts}
	`,
	deleteExternalObjectById: gql`
		mutation deleteExternalObjectById($externalObjectId: UUID!) {
			deleteExternalObjectById(externalObjectId: $externalObjectId)
		}
	`,
	updateUserPermissions: gql`
		mutation updateUserPermissions(
			$accountId: Int!
			$userId: Int!
			$permissionsToUpdate: [CollectionPermissionUpdateInput]
			$permissionsToRemove: [String]
		) {
			updateUserPermissions(
				accountId: $accountId
				userId: $userId
				permissionsToUpdate: $permissionsToUpdate
				permissionsToRemove: $permissionsToRemove
			) {
				...CollectionPermissionParts
			}
		}
		${Fragments.CollectionPermissionParts}
	`,
	createCollectionPermission: gql`
		mutation createCollectionPermission(
			$accountId: Int!
			$collectionPermission: CollectionPermissionCreateInput!
		) {
			createCollectionPermission(
				accountId: $accountId
				collectionPermission: $collectionPermission
			) {
				...CollectionPermissionParts
			}
		}
		${Fragments.CollectionPermissionParts}
	`,
	updateCollectionPermissionById: gql`
		mutation updateCollectionPermissionById(
			$accountId: Int!
			$collectionPermissionId: UUID!
			$updates: CollectionPermissionUpdateInput!
		) {
			updateCollectionPermissionById(
				accountId: $accountId
				collectionPermissionId: $collectionPermissionId
				updates: $updates
			) {
				...CollectionPermissionParts
			}
		}
		${Fragments.CollectionPermissionParts}
	`,
	deleteCollectionPermissionById: gql`
		mutation deleteCollectionPermissionById(
			$accountId: Int!
			$collectionPermissionId: UUID!
		) {
			deleteCollectionPermissionById(
				accountId: $accountId
				collectionPermissionId: $collectionPermissionId
			)
		}
	`,
	createPlaylist: gql`
		mutation createPlaylist($accountId: Int!) {
			createPlaylist(accountId: $accountId) {
				...PlaylistParts
			}
		}
		${Fragments.PlaylistParts}
	`,
	deletePlaylistById: gql`
		mutation deletePlaylistById($accountId: Int!, $playlistId: UUID!) {
			deletePlaylistById(accountId: $accountId, playlistId: $playlistId)
		}
	`,
	updatePlaylistById: gql`
		mutation updatePlaylistById(
			$accountId: Int!
			$playlistId: UUID!
			$playlist: PlaylistInput
		) {
			updatePlaylistById(
				accountId: $accountId
				playlistId: $playlistId
				playlist: $playlist
			) {
				...PlaylistParts
			}
		}
		${Fragments.PlaylistParts}
	`,
	createPromotion: gql`
		mutation createPromotion(
			$accountId: Int!
			$collectionId: UUID
			$itemId: UUID
		) {
			createPromotion(
				accountId: $accountId
				collectionId: $collectionId
				itemId: $itemId
			) {
				...PromotionParts
			}
		}
		${Fragments.PromotionParts}
	`,
	updatePromotionById: gql`
		mutation updatePromotionById(
			$accountId: Int!
			$promotionId: UUID!
			$promotion: PromotionInput
			$promotionFileName: String
		) {
			updatePromotionById(
				accountId: $accountId
				promotionId: $promotionId
				promotion: $promotion
				promotionFileName: $promotionFileName
			) {
				...PromotionParts
			}
		}
		${Fragments.PromotionParts}
	`,
	deletePromotionById: gql`
		mutation deletePromotionById($accountId: Int!, $promotionId: UUID!) {
			deletePromotionById(accountId: $accountId, promotionId: $promotionId)
		}
	`,
	createShare: gql`
		mutation createShare($share: ShareInput!) {
			createShare(share: $share) {
				...ShareParts
			}
		}
		${Fragments.ShareParts}
	`,
	updateShareById: gql`
		mutation updateShareById($shareId: UUID!, $share: ShareInput!) {
			updateShareById(shareId: $shareId, share: $share) {
				...ShareParts
			}
		}
		${Fragments.ShareParts}
	`,
	checkForOverlappingPromotions: gql`
		mutation checkForOverlappingPromotions(
			$promotionId: UUID!
			$promotion: PromotionInput
		) {
			checkForOverlappingPromotions(
				promotionId: $promotionId
				promotion: $promotion
			) {
				__typename
				...PromotionParts
			}
		}
		${Fragments.PromotionParts}
	`,
	generateEpisodeTitle: gql`
		mutation generateEpisodeTitle($episodeId: UUID!, $accountId: Int!) {
			generateEpisodeTitle(episodeId: $episodeId, accountId: $accountId)
		}
	`,
	generateSeoMeta: gql`
		mutation generateSeoMeta($episodeId: UUID!, $accountId: Int!) {
			generateSeoMeta(episodeId: $episodeId, accountId: $accountId)
		}
	`,
	generateEpisodeDescription: gql`
		mutation generateEpisodeDescription($episodeId: UUID!, $accountId: Int!) {
			generateEpisodeDescription(episodeId: $episodeId, accountId: $accountId)
		}
	`,
	generateShowNotes: gql`
		mutation generateShowNotes($episodeId: UUID!, $accountId: Int!) {
			generateShowNotes(episodeId: $episodeId, accountId: $accountId)
		}
	`,
	generateSocialMetaDescription: gql`
		mutation generateSocialMetaDescription($episodeId: UUID!, $accountId: Int!) {
			generateSocialMetaDescription(episodeId: $episodeId, accountId: $accountId)
		}
	`,

};
