import React, { useState, useEffect, useMemo } from 'react';
import { Prompt } from 'react-router';
import * as Sentry from '@sentry/browser';
import { toast } from 'react-toastify';
import API from 'services/api';
import Toast from 'services/toast';
import { trackEvent } from 'services/vitally';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Uppy from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';
import GoogleDrive from '@uppy/google-drive';
import { useQuery } from '@apollo/client/react/hooks';
import { v4 as uuid } from 'uuid';

// Redux
import { connect } from 'react-redux';
import { dispatch } from 'store/store';
import { DIALOGS } from 'store/dialogs';
import {
	setEpisodeUpdates,
	setEpisodeResourceCreates,
	setEpisodeResourceUpdates,
	setEpisodeResourceDeletes,
	updateEpisodeUpload,
	removeEpisodeUpload,
	removeAllEpisodeUploads,
	addEpisodeUpload,
	removeAllEpisodeFileUploads,
	removeAllRSSFileUploads,
} from 'store/reducers/data/episode';
import { hideUploadWizardOnLoad } from 'store/reducers/ui/modals/uploadWizardModal';

import queries from 'services/graphql/queries';

// Internal Constants
import {
	EPISODE_UPLOAD_TYPES,
	getContentType,
	CONTENT_TYPE,
	UPLOAD_WIZARD_STEPS,
	ACTIVE_STATUS,
} from 'components/constants';

// External Components
import { FaUpload } from 'react-icons/fa';
import { Row, Form, Col, Container, Card, FormGroup, Button, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';

// Internal Components
import ProgressBar from 'components/shared/progressBar';
import EpisodeThumbnail from './episodeThumbnail';
import EpisodeYoutubeThumbnail from './episodeYoutubeThumbnail';
import InformationSection from './informationSection';
import SocialSection from './socialSection';
import EpisodeTags from './tags';
import EpisodeGuests from './episodeGuests';
import EpisodeResources from './episodeResources';
import AudioPlayer from 'components/shared/audioPlayer';
import UploadWizard from './uploadWizard';
import MetaAndSearchResultsSection from './metaAndSearchResults';
import EpisodeThemes from './episodeThemes';
import { getPermission, ObjectPermissionSlugs } from 'utils/permissionsManager';
import { isUrlValid } from '../../../../../utils';

const EpisodeInfo = (props) => {
	const {
		accountId,
		episode,
		resources = [],
		podcast,
		permissions,
		enableVideo,
		refetch = () => {},
		account,
		youtubeIntegration,
		/* Redux props start */
		episodeUpdates,
		episodeResourceCreates,
		episodeResourceUpdates,
		episodeResourceDeletes,
		episodeUploads,
		uploadWizardModal,
		/* Redux props end */
	} = props;

	// Local version of episode with any unsaved edits
	const [localEpisode, setLocalEpisode] = useState({
		...episode,
	});

	const [localResources, setLocalResources] = useState([...resources]);
	const [editsMade, setEditsMade] = useState(false);

	// File Manager
	const [filesToUpload, setFilesToUpload] = useState([]);

	const {
		id: episodeId,
		createdAt,
		episode: episodeNo,
		season,
		name,
		thumbnail,
		youtubeThumbnail,
		storageLink: _storageLink,
		description,
		duration,
		keywords: tags = [],
		metaDescription,
		showNotes,
		guests,
		videoLink,
		rssAudioStorageLink: _rssAudioStorageLink,
		explicit,
		uploadType,
		type,
		seoTitle,
		socialCardDescription,
		episodeThemeMatches,
		alternateRssLink: _alternateRssLink,
		youtubeDescription
	} = localEpisode;

	/* Feature Flags & User Permissions */
	const { featureThemes, featureAiSummarySuggestionAccess } = useFlags();

	const episodePermission = permissions
		? getPermission(permissions, ObjectPermissionSlugs.EPISODES, podcast.id)
				.rolePermission
		: {};
	const themesPermission = permissions
		? getPermission(permissions, ObjectPermissionSlugs.THEMES).rolePermission
		: {};

	/* Component States */
	const [editStorageLink, setEditStorageLink] = useState(_storageLink);
	const [editRssStorageLink, setEditRssStorageLink] =
		useState(_rssAudioStorageLink);
	const [editAlternateRssLink, setEditAlternateRssLink] =
		useState(_alternateRssLink);
	const [showUploadWizard, setShowUploadWizard] = useState(
		uploadWizardModal.showOnLoad || false
	);
	const [episodeUploadProgress, setEpisodeUploadProgress] = useState(0);
	const [rssUploadProgress, setRssUploadProgress] = useState(0);
	const [uploadToYoutube, setUploadToYoutube] = useState(false);
	const [wizardStep, setWizardStep] = useState(
		UPLOAD_WIZARD_STEPS.upload_content
	);
	const [generatingAll, setGeneratingAll] = useState(false);
	const [infoSectionAllGenerated, setInfoSectionAllGenerated] = useState(false);
	const [socialSectionAllGenerated, setSocialSectionAllGenerated] = useState(false);
	const [metaSectionAllGenerated, setMetaSectionAllGenerated] = useState(false);

	// Creates a list of unique themes associated to the episode
	const episodeThemes =
		[
			...new Set(
				episodeThemeMatches?.map((episodeThemeMatch) => {
					return episodeThemeMatch.theme;
				})
			),
		] || [];

	const { thumbnail: showThumbnail, collectionType, autoPublishYoutube } = podcast;

	const podcastIsHosted = podcast.externalId ? true : false;
	const episodeContentType = getContentType(podcast.collectionType);

	const isVideoEnabled = account?.accounts[accountId].enableVideo;

	useEffect(() => {
		if (editStorageLink !== _storageLink) {
			updateEpisodeProperty('storageLink', editStorageLink);
		}
	}, [editStorageLink]);

	useEffect(() => {
		if (editRssStorageLink !== _rssAudioStorageLink) {
			updateEpisodeProperty('rssAudioStorageLink', editRssStorageLink);
		}
	}, [editRssStorageLink]);

	useEffect(() => {
		if (editAlternateRssLink !== _alternateRssLink) {
			updateEpisodeProperty('alternateRssLink', editAlternateRssLink);
		}
	}, [editAlternateRssLink]);

	// Check if user discarded changes and reset values back to original
	useEffect(() => {
		if (
			editsMade &&
			Object.keys(episodeUpdates).length === 0 &&
			episodeResourceCreates.length === 0 &&
			episodeResourceDeletes.length === 0 &&
			episodeResourceUpdates.length === 0
		) {
			refetch();
			setEditsMade(false);
		}
	}, [
		episodeUpdates,
		episodeResourceCreates,
		episodeResourceDeletes,
		episodeResourceUpdates,
	]);

	useEffect(() => {
		if (infoSectionAllGenerated && socialSectionAllGenerated && metaSectionAllGenerated) {
			setGeneratingAll(false);
			setInfoSectionAllGenerated(false);
			setSocialSectionAllGenerated(false);
			setMetaSectionAllGenerated(false);
		}
	}, [infoSectionAllGenerated, socialSectionAllGenerated, metaSectionAllGenerated]);

	const updateEpisodeProperty = (property, value) => {
		// Make sure property exists
		if (episode.hasOwnProperty(property)) {
			let localEpisodeUpdates = { ...episodeUpdates };

			// Storage Link will only be reset to empty string if an episode upload fails
			if (property === 'storageLink' && value === '') {
				delete localEpisodeUpdates['storageLink'];
			} else if (property === 'rssAudioStorageLink' && value === '') {
				delete localEpisodeUpdates['rssAudioStorageLink'];
			} else {
				localEpisodeUpdates[property] = value;
			}

			if (property === 'type' && value !== 'full') {
				localEpisodeUpdates.episode = null;
			}

			// Call to update episodeUpdates in EpisodeDetail
			dispatch(setEpisodeUpdates(localEpisodeUpdates));

			// Update localEpisode to include unsaved edits
			setLocalEpisode({
				...localEpisode,
				[property]: value,
			});

			setEditsMade(true);
		}
	};

	const updateEpisodeResources = (updatedResourceList) => {
		let resourcesToCreate = [];
		let resourcesToUpdate = [];
		let resourcesToDelete = [];

		// Loop through existing resources and mark for deletion if not found in updatedResourceList
		resources.map((resource) => {
			if (!updatedResourceList.some((r) => r.id === resource.id)) {
				resourcesToDelete.push(resource);
			}
		});

		updatedResourceList.map((updatedResource) => {
			// Check if current resource exists
			if (resources.some((r) => r.id === updatedResource.id)) {
				resourcesToUpdate.push(updatedResource);
			} else {
				resourcesToCreate.push(updatedResource);
			}
		});

		dispatch(setEpisodeResourceCreates(resourcesToCreate));
		dispatch(setEpisodeResourceUpdates(resourcesToUpdate));
		dispatch(setEpisodeResourceDeletes(resourcesToDelete));

		// Update localResources to include new and updated resources
		setLocalResources([...resourcesToCreate, ...resourcesToUpdate]);
	};

	const createPromptMessage = () => {
		let dialog = DIALOGS.EPISODE_UNSAVED_CHANGES;
		let messageText =
			'You have unsaved changes. Are you sure you want to leave?';

		if (episodeUpdates.storageLink === 'uploading') {
			messageText =
				'Your upload will fail if you leave now. Are you sure you want to leave?';
		}

		return JSON.stringify({
			dialog,
			messageText: messageText,
			episodeId: episode.id,
			episodeUploads: episodeUploads,
		});
	};

	const onUploadFileSelect = (episodeUpload) => {
		if (episodeUpload.id && episodeUpload.fileType) {
			let newFilesToUpload = filesToUpload.filter(
				(file) => file.fileType !== episodeUpload.fileType
			);

			newFilesToUpload = newFilesToUpload.concat(episodeUpload);

			setFilesToUpload(newFilesToUpload);
		}
	};

	/** UPPY START **/
	const { data: fileUploadValidationData } = useQuery(
		queries.getFileUploadValidationSecret
	);

	const allowedFileTypes = ['audio/*'];

	if (enableVideo) {
		allowedFileTypes.push('video/*');
	}

	const uppyConfig = {
		autoProceed: false,
		meta: {
			accountId: accountId,
			fileUploadValidationSecret:
				fileUploadValidationData?.getFileUploadValidationSecret,
		},
		restrictions: {
			maxNumberOfFiles: 1,
			allowedFileTypes: allowedFileTypes,
		},
	};
	
	const uppyXhrConfig = {
		endpoint: `${process.env.REACT_APP_FILE_UPLOADER_HOST}/episodeUpload`,
		retryDelays: [0, 1000, 3000, 5000],
	};

	const uppyGoogleDriveConfig = {
		companionUrl: `http://localhost:8089/companion`,
	};

	const uppyFileAdded = (uppy, file, fileType) => {
		const nameParts = file.name.split('.');
		const fileExt = nameParts[nameParts.length - 1];
		const fileName = `${episodeId}.${fileExt}`;

		uppy.setFileMeta(file.id, {
			name: fileName,
			uploadType: fileType,
		});

		const episodeUpload = {
			id: uuid(),
			fileName: fileName,
			fileType: fileType,
		};

		setFilesToUpload(uppy.getFiles().length)

		dispatch(addEpisodeUpload(episodeUpload));
	};

	const uppyFileRemoved = (uppy) => {
		dispatch(removeAllEpisodeFileUploads())

		setFilesToUpload(uppy.getFiles().length)
		setUploadToYoutube(uppy.getFiles().length > 0)

	}

	const uppyOnUpload = (data, fileType) => {
		Toast.info(
			'Upload in progress',
			<span>Closing your browser will result in losing your file!</span>,
			{ autoClose: false, closeOnClick: false }
		);

		if (fileType === 'episode') {
			setEditStorageLink('uploading');
			setEpisodeUploadProgress(1);
		} else {
			setEditRssStorageLink('uploading');
			setRssUploadProgress(1);
		}
	};

	const uppyOnProgress = (progress, fileType) => {
		if (fileType === 'episode') {
			setEpisodeUploadProgress(progress);
		} else {
			setRssUploadProgress(progress);
		}

		if (progress > 99) {
			setTimeout(() => {
				if (fileType === 'episode') {
					setEpisodeUploadProgress(0);
				} else {
					setRssUploadProgress(0);
				}
			}, 1800);
		}
	};

	const uppyOnUploadError = (error, fileType) => {
		toast.dismiss();
		Toast.error('Unable to upload episode', '', {
			autoClose: false,
			closeOnClick: true,
		});

		// Reset upload states
		if (fileType === 'episode') {
			setEditStorageLink('');
			setEpisodeUploadProgress(null);
		} else {
			setEditRssStorageLink('');
			setRssUploadProgress(null);
		}
	};

	const uppyOnUploadSuccess = async (file) => {
		const fileName = `${file.meta.uploadType === 'rss' ? 'rss/' : ''}${
			file.meta.name
		}`;

		const signedResponse = await API.getSignedUrls({
			filename: fileName,
			accountId,
			contentType: file.meta.type,
		});

		// Strip out the querystring that holds the read token
		const readUrl = signedResponse.readUrl.split('?')[0];

		const nameParts = file.name.split('.');
		let fileExt = nameParts[nameParts.length - 1];

		if (file.meta.uploadType === 'episode') {
			setEditStorageLink(readUrl);
		} else {
			setEditRssStorageLink(readUrl);
		}

		trackEvent('media-upload', {
			itemName: name,
			collectionName: podcast.name,
			mediaType: fileExt,
			fileType: file.meta.uploadType === 'episode' ? 'primary' : 'rss-only',
		});

		if (file.meta.uploadType === 'episode') {
			dispatch(removeAllEpisodeUploads());
		} else {
			dispatch(removeAllRSSFileUploads());
		}

		toast.dismiss();
		Toast.success(
			'Upload complete!',
			"We've auto-saved this episode with your file.",
			{ autoClose: true }
		);
	};

	const uppyRegisterErrors = (result) => {
		for(let i = 0; i < result.failed.length; i++) {
			const failed = result.failed[i];
			const err = new Error(failed.error);
			Sentry.withScope(scope => {

				scope.setExtra("details", {
					...failed.progress,
					...failed.meta,
					size: failed.size,
					...(failed.response != undefined ? {
						response_status: failed.response.status,
						response_body: failed.response.body
					} : {}),
				})
				Sentry.captureException(err)
			});
		}
	}

	// Need to create multiple instances of Uppy in order to display separate
	// upload zones to display in the upload wizard
	const uppyEpisode = useMemo(() => {
		return new Uppy(uppyConfig)
			.use(XHRUpload, uppyXhrConfig)
			.use(GoogleDrive, uppyGoogleDriveConfig)
			.on('file-added', (file) => uppyFileAdded(uppyEpisode, file, 'episode'))
			.on('file-removed', (file) => uppyFileRemoved(uppyEpisode))
			.on('upload', (data) => uppyOnUpload(data, 'episode'))
			.on('progress', (progress) => uppyOnProgress(progress, 'episode'))
			.on('upload-error', (_file, error) => uppyOnUploadError(error, 'episode'))
			.on('upload-success', (file) => uppyOnUploadSuccess(file, 'episode'))
			.on('complete', (result) => uppyRegisterErrors(result));
	}, [fileUploadValidationData]);
	const uppyRss = useMemo(() => {
		return new Uppy({
			...uppyConfig,
			restrictions: {
				...uppyConfig.restrictions,
				allowedFileTypes: ['audio/*'],
			},
		})
			.use(XHRUpload, uppyXhrConfig)
			.use(GoogleDrive, uppyGoogleDriveConfig)
			.on('file-added', (file) => uppyFileAdded(uppyRss, file, 'rss'))
			.on('file-removed', (file) => dispatch(removeAllRSSFileUploads()))
			.on('upload', (data) => uppyOnUpload(data, 'rss'))
			.on('progress', (progress) => uppyOnProgress(progress, 'rss'))
			.on('upload-error', (error) => uppyOnUploadError(error, 'rss'))
			.on('upload-success', (file) => uppyOnUploadSuccess(file, 'rss'))
			.on('complete', (result) => uppyRegisterErrors(result));
	}, [fileUploadValidationData]);

	// Clean up event handlers etc when the component unmounts.
	useEffect(() => {
		return () => {
			uppyEpisode.close();
			uppyRss.close();
		};
	}, []);
	/** UPPY END **/

	const getValidYoutubeThumbnail = () => {
		const validExts = ['jpg', 'jpeg', 'png', 'gif'];
		const isYTTSet = validExts.includes((youtubeThumbnail || '').substring((youtubeThumbnail || '').length - 3).toLowerCase());
		const isETSet = validExts.includes((thumbnail || '').substring((thumbnail || '').length - 3).toLowerCase());
		const isPTSet = validExts.includes((showThumbnail || '').substring((showThumbnail || '').length - 3).toLowerCase());

		if(isYTTSet)
			return youtubeThumbnail;
		if(isETSet)
			return thumbnail;
		if(isPTSet)
			return showThumbnail;

		return undefined;
	}

	const onUploadStart = () => {
		if (
			uploadToYoutube &&
			wizardStep !== UPLOAD_WIZARD_STEPS.publish_to_youtube
		) {
			setWizardStep(UPLOAD_WIZARD_STEPS.publish_to_youtube);
			return;
		}

		setShowUploadWizard(false);
		dispatch(hideUploadWizardOnLoad());

		// Start Uppy upload
		if (uppyEpisode.getFiles()?.length > 0) {
			uppyEpisode.upload();
		}
		if (uppyRss.getFiles()?.length > 0) {
			uppyRss.upload();
		}
	};

	const onUploadWizardClose = () => {
		if (wizardStep === UPLOAD_WIZARD_STEPS.publish_to_youtube) {
			onUploadStart();
		} else {
			setFilesToUpload([]);
		}
		setShowUploadWizard(false);
		dispatch(removeAllEpisodeUploads());
		dispatch(hideUploadWizardOnLoad());
	};

	const onGenerateAll = () => {
		if(episode.status === ACTIVE_STATUS.active)
			setGeneratingAll(true);
	}

	

	/** Render **/
	return (
		<React.Fragment>
			<Prompt
				when={
					Object.keys(episodeUpdates).length > 0 ||
					episodeResourceCreates.length > 0 ||
					episodeResourceUpdates.length > 0 ||
					episodeResourceDeletes.length > 0 ||
					episodeUploads.length > 0
				}
				message={createPromptMessage}
			/>
			<Container fluid={true} className="episode-info p-4">
				
				{podcastIsHosted && episodePermission.canEdit && <UploadWizard
					showUploadWizard={showUploadWizard}
					setShowUploadWizard={setShowUploadWizard}
					updateEpisodeProperty={updateEpisodeProperty}
					podcastIsHosted={podcastIsHosted}
					episodePermission={episodePermission}
					episodeId={episodeId}
					podcast={podcast}
					accountId={accountId}
					enableVideo={enableVideo}
					storageLink={editStorageLink}
					rssAudioStorageLink={editRssStorageLink}
					uploadType={uploadType}
					onSelect={onUploadFileSelect}
					onUploadStart={onUploadStart}
					onClose={onUploadWizardClose}
					episodeContentType={episodeContentType}
					uploadToYoutube={uploadToYoutube}
					setUploadToYoutube={setUploadToYoutube}
					wizardStep={wizardStep}
					setWizardStep={setWizardStep}
					description={description}
					name={name}
					showThumbnail={showThumbnail}
					thumbnail={thumbnail}
					filesToUpload={filesToUpload}
					youtubeIntegration={youtubeIntegration}
					uppyEpisode={uppyEpisode}
					uppyRss={uppyRss}
				/>}
				<Row>
					<Col xs={12} md={8}>
						<Container className="p-0">
							<Row>
								<Col className="d-flex justify-content-between align-items-center">
									<div className="section-heading">
										<h5 className="label capitalize">
											{episodeContentType} information
										</h5>
										<p>
											<span className="capitalize">{episodeContentType}</span>{' '}
											information will be displayed on Casted{' '}
											{episodeContentType === CONTENT_TYPE.episode
												? 'episode '
												: ''}
											pages and takeaways.
										</p>
									</div>
									{featureAiSummarySuggestionAccess && (
										<>
											{generatingAll ? (
												<Spinner
													className="generate-spinner"
													animation="border"
												/>
											) : (
												<OverlayTrigger
													placement={episode.status === ACTIVE_STATUS.active ? 'top' : 'right'}
													overlay={
														<Tooltip id="generate-all-tooltip">
															{episode.status === ACTIVE_STATUS.active ? 'Generate Suggested Title & Descriptions' : 'Waiting for Transcript' } 
														</Tooltip>
													}
												>
													<Button
														variant="light"
														className="label-btn-text generate-all-btn"
														onClick={onGenerateAll}
														disabled={generatingAll || episode.status !== ACTIVE_STATUS.active}
													>
														<div>
															<svg
																width="16"
																height="17"
																viewBox="0 0 20 21"
																fill="currentColor"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	d="M6.58969 3.18516L6.72469 3.25266C7.27031 3.53391 7.71469 3.97828 7.99594 4.52391L8.06344 4.65891C8.33344 5.19328 9.09844 5.19328 9.37406 4.65891L9.44156 4.52391C9.72281 3.97828 10.1672 3.53391 10.7128 3.25266L10.8478 3.18516C11.3822 2.91516 11.3822 2.15016 10.8478 1.87453L10.7128 1.80703C10.1672 1.52578 9.72281 1.08141 9.44156 0.535781L9.37406 0.400781C9.10406 -0.133594 8.33906 -0.133594 8.06344 0.400781L7.99594 0.535781C7.71469 1.08141 7.27031 1.52578 6.72469 1.80703L6.58969 1.87453C6.05531 2.14453 6.05531 2.90953 6.58969 3.18516Z"
																	fill="currentColor"
																/>
																<path
																	d="M7.65281 8.45578C8.20406 8.17453 8.20406 7.39266 7.65281 7.11141L6.83156 6.68953C6.10031 6.31266 5.50406 5.71641 5.12719 4.98516L4.70531 4.16391C4.42406 3.61266 3.64219 3.61266 3.36094 4.16391L2.93906 4.98516C2.56219 5.71641 1.96594 6.31266 1.23469 6.68953L0.413438 7.11141C-0.137812 7.39266 -0.137812 8.17453 0.413438 8.45578L1.23469 8.87766C1.96594 9.25453 2.56219 9.85078 2.93906 10.582L3.36094 11.4033C3.64219 11.9545 4.42406 11.9545 4.70531 11.4033L5.12719 10.582C5.50406 9.85078 6.10031 9.25453 6.83156 8.87766L7.65281 8.45578Z"
																	fill="currentColor"
																/>
																<path
																	d="M18.2053 11.8195L17.3728 11.392C16.3659 10.8802 15.5616 10.0758 15.0497 9.06891L14.6222 8.23641C14.2453 7.49391 13.4916 7.03266 12.6591 7.03266C11.8266 7.03266 11.0728 7.49391 10.6959 8.23641L10.2684 9.06891C9.75656 10.0758 8.95219 10.8802 7.94531 11.392L7.11281 11.8195C6.37031 12.1964 5.90906 12.9502 5.90906 13.7827C5.90906 14.6152 6.37031 15.3689 7.11281 15.7458L7.94531 16.1733C8.95219 16.6852 9.75656 17.4895 10.2684 18.4964L10.6959 19.3289C11.0728 20.0714 11.8266 20.5327 12.6591 20.5327C13.4916 20.5327 14.2453 20.0714 14.6222 19.3289L15.0497 18.4964C15.5616 17.4895 16.3659 16.6852 17.3728 16.1733L18.2053 15.7458C18.9478 15.3689 19.4091 14.6152 19.4091 13.7827C19.4091 12.9502 18.9478 12.1964 18.2053 11.8195ZM17.4403 14.2439L16.6078 14.6714C15.2803 15.3464 14.2228 16.4039 13.5478 17.7314L13.1203 18.5639C12.9909 18.817 12.7547 18.8452 12.6591 18.8452C12.5634 18.8452 12.3272 18.817 12.1978 18.5639L11.7703 17.7314C11.0953 16.4039 10.0378 15.3464 8.71031 14.6714L7.87781 14.2439C7.62469 14.1145 7.59656 13.8783 7.59656 13.7827C7.59656 13.687 7.62469 13.4508 7.87781 13.3214L8.71031 12.8939C10.0378 12.2189 11.0953 11.1614 11.7703 9.83391L12.1978 9.00141C12.3272 8.74828 12.5634 8.72016 12.6591 8.72016C12.7547 8.72016 12.9909 8.74828 13.1203 9.00141L13.5478 9.83391C14.2228 11.1614 15.2803 12.2189 16.6078 12.8939L17.4403 13.3214C17.6934 13.4508 17.7216 13.687 17.7216 13.7827C17.7216 13.8783 17.6934 14.1145 17.4403 14.2439Z"
																	fill="currentColor"
																/>
															</svg>{' '}
															GENERATE
														</div>
													</Button>
												</OverlayTrigger>
											)}
										</>
									)}
								</Col>
							</Row>
							<Row>
								<Col>
									<div className="mb-4">
										<Card className="p-3">
											<InformationSection
												name={name}
												episodeNo={episodeNo}
												season={season}
												explicit={explicit}
												onChange={updateEpisodeProperty}
												canEdit={episodePermission.canEdit}
												isHosted={podcastIsHosted}
												type={type}
												episodeContentType={episodeContentType}
												episodeId={episodeId}
												accountId={accountId}
												editsMade={editsMade}
												generatingAll={generatingAll}
												setInfoSectionAllGenerated={setInfoSectionAllGenerated}
												disableGenerateButton={episode.status !== ACTIVE_STATUS.active}
											/>
										</Card>
									</div>
									{podcast.disableLandingPages && (
										<FormGroup>
											<Form.Label>Alternate Show Page</Form.Label>
											<p className="admin-section--checkbox">
												RSS feeds typically have a link to the show's page.
												Since we are disabling landing pages, where do you want
												this link to go?
											</p>
											<Form.Control
												className="shared-episode-link-text"
												id="alternateShowPage"
												placeholder="Please enter a valid URL. (e.g. https://www.casted.us)"
												onChange={(e) =>
													setEditAlternateRssLink(e.target.value)
												}
												onBlur={(e) =>
													setEditAlternateRssLink(e.target.value.trim())
												}
												value={editAlternateRssLink}
												isInvalid={
													!editAlternateRssLink == '' &&
													editAlternateRssLink != episode.alternateRssLink &&
													!isUrlValid(editAlternateRssLink)
												}
												isValid={
													editAlternateRssLink != episode.alternateRssLink &&
													isUrlValid(editAlternateRssLink)
												}
											/>
										</FormGroup>
									)}
									{!podcast.disableLandingPages && (
										<>
											<div className="section-heading">
												<h5 className="label">Meta and Search Results</h5>
												<p>
													Set up how your {episodeContentType} will show up on
													search results pages.
												</p>
											</div>
											<div className="mb-4">
												<Card className="p-3">
													<MetaAndSearchResultsSection
														seoTitle={seoTitle}
														metaDescription={metaDescription}
														onChange={updateEpisodeProperty}
														canEdit={episodePermission.canEdit}
														isHosted={podcastIsHosted}
														episodeId={episodeId}
														accountId={accountId}
														editsMade={editsMade}
														generatingAll={generatingAll}
														disableGenerateButton={episode.status !== ACTIVE_STATUS.active}
														setMetaSectionAllGenerated={
															setMetaSectionAllGenerated
														}
													/>
												</Card>
											</div>
										</>
									)}
									<div className="section-heading">
										<h5 className="label capitalize">
											{episodeContentType} Descriptions
										</h5>
										<p>
											{episodeContentType === CONTENT_TYPE.episode
												? 'Episode descriptions appear in RSS feeds;'
												: ''}{' '}
											The social card description appears in preview cards on
											social platforms.
										</p>
									</div>
									<div className="mb-4">
										<Card className="p-3">
											<SocialSection
												showNotes={showNotes}
												description={description}
												socialCardDescription={socialCardDescription}
												onChange={updateEpisodeProperty}
												canEdit={episodePermission.canEdit}
												isHosted={podcastIsHosted}
												episodeContentType={episodeContentType}
												episodeId={episodeId}
												accountId={accountId}
												generatingAll={generatingAll}
												disableGenerateButton={episode.status !== ACTIVE_STATUS.active}
												editsMade={editsMade}
												autoPublishYoutube={autoPublishYoutube}
												youtubeDescription={youtubeDescription}
												uploadType={uploadType}
												setSocialSectionAllGenerated={
													setSocialSectionAllGenerated
												}
											/>
										</Card>
									</div>
								</Col>
							</Row>
							<Row>
								<Col>
									<div className="mb-4">
										<div className="section-heading">
											<h5 className="label">{`${
												episodeContentType === CONTENT_TYPE.episode
													? 'Episode Guests'
													: 'Guests'
											}`}</h5>
											<p>
												Guests will be added to your episode page so listeners
												can learn more about them.
											</p>
										</div>
										<EpisodeGuests
											guests={guests}
											onChange={updateEpisodeProperty}
											canEdit={episodePermission.canEdit}
										/>
									</div>
								</Col>
							</Row>
							<Row>
								<Col>
									<div className="mb-4">
										<div className="section-heading">
											<h5 className="label">Resource area</h5>
											<p>
												Send users from your episode's page to your choice of
												external pages or resources. Drag and drop to change the
												order in which resources appear on the episode page.
											</p>
										</div>
										<EpisodeResources
											episodeId={episodeId}
											onChange={updateEpisodeResources}
											canEdit={episodePermission.canEdit}
											resources={localResources}
										/>
									</div>
								</Col>
							</Row>
						</Container>
					</Col>
					<Col xs={12} md={4}>
						<Container className="p-0">
							<Row>
								<Col>
									<div className="mb-4">
										{podcastIsHosted && episodePermission.canEdit && <div className="section-heading">
											<h5
												className="label mb-3"
												style={{ marginTop: '1.35rem' }}
											>
												{editStorageLink && editStorageLink !== 'uploading'
													? `${
															episodeContentType === CONTENT_TYPE.episode
																? 'Episode'
																: ''
													  } Player`
													: `Upload ${episodeContentType}`}
											</h5>
										</div>}
										<div className="mb-4">
											{!editStorageLink ? (
												podcastIsHosted && episodePermission.canEdit ? <Card
													className="upload-wizard-open-zone p-3"
													onClick={() => setShowUploadWizard(true)}
												>
													<div>
														<FaUpload size={30} className="mb-4" />
														Click to open Upload Wizard
													</div>
												</Card> : <></>
											) : editStorageLink === 'uploading' ? (
												<Card className="episode-info--player p-3">
													<ProgressBar uploadProgress={episodeUploadProgress} />
												</Card>
											) : (
												<Card className="episode-info--player p-3">
													<AudioPlayer
														url={editStorageLink}
														isVideo={
															videoLink &&
															isVideoEnabled &&
															uploadType === EPISODE_UPLOAD_TYPES.VIDEO
																? true
																: false
														}
														className={`${
															videoLink &&
															isVideoEnabled &&
															uploadType === EPISODE_UPLOAD_TYPES.VIDEO
																? 'video-player'
																: ''
														}`}
													/>
												</Card>
											)}
										</div>
									</div>

									{episodeContentType === CONTENT_TYPE.episode &&
										uploadType === 'video' && (
											<div className="mb-4">
												<div className="section-heading">
													<h5 className="label">
														{`${
															editRssStorageLink &&
															editRssStorageLink !== 'uploading'
																? ''
																: 'Upload '
														}`}
														RSS Only Audio File
													</h5>
													<p>
														Optionally add a separate audio file for RSS feeds
														only. Casted will use audio from your video file for
														transcripts, clips, and videograms.
													</p>
												</div>
												<div className="mb-4">
													{!editRssStorageLink ? (
														<Card
															className="upload-wizard-open-zone p-3"
															onClick={() => setShowUploadWizard(true)}
														>
															<div>
																<FaUpload size={30} className="mb-4" />
																Click to open Upload Wizard
															</div>
														</Card>
													) : editRssStorageLink === 'uploading' ? (
														<Card className="rss-audio-uploader p-3">
															<ProgressBar uploadProgress={rssUploadProgress} />
														</Card>
													) : (
														<div>
															<Card className="rss-audio-uploader p-3">
																<AudioPlayer
																	url={editRssStorageLink}
																	isVideo={false}
																/>
																<div
																	onClick={() => setShowUploadWizard(true)}
																	className="replace-rss-label"
																>
																	Replace RSS Audio File
																</div>
															</Card>
														</div>
													)}
												</div>
											</div>
										)}

									<div className="mb-4">
										<div className="section-heading">
											<h5 className="label mb-3">
												{`${
													episodeContentType === CONTENT_TYPE.episode
														? 'Episode'
														: ''
												}`}{' '}
												Thumbnail
											</h5>
										</div>
										<Card className="thumbnail-upload-container p-3">
											<EpisodeThumbnail
												thumbnail={thumbnail ? thumbnail : showThumbnail}
												onChange={updateEpisodeProperty}
												canEdit={podcastIsHosted && episodePermission.canEdit}
											/>
										</Card>
										<div className="section-heading">
											<h5 className="label mt-3">
												{`${
													episodeContentType === CONTENT_TYPE.episode
														? 'Episode'
														: ''
												}`}{' '}
												Youtube Thumbnail
											</h5>
										</div>
										<Card className="thumbnail-upload-container p-3">
											<p className="hint">
												Recommended size 1280 x 720 pixels (16:9).
											</p>
											<EpisodeYoutubeThumbnail
												thumbnail={getValidYoutubeThumbnail()}
												onChange={updateEpisodeProperty}
												canEdit={podcastIsHosted && episodePermission.canEdit}
											/>
										</Card>
									</div>

									<div className="mb-4">
										<div className="section-heading">
											<h5 className="label mb-3">
												{`${
													episodeContentType === CONTENT_TYPE.episode
														? 'Episode'
														: ''
												}`}{' '}
												tags
											</h5>
										</div>
										<EpisodeTags
											tags={tags}
											onChange={updateEpisodeProperty}
											canEdit={episodePermission.canEdit}
										/>
									</div>

									{featureThemes &&
										themesPermission.canView &&
										episodeThemes.length > 0 && (
											<div className="mb-4">
												<div className="section-heading">
													<h5 className="label mb-3">Themes</h5>
												</div>
												<EpisodeThemes
													accountId={accountId}
													themes={episodeThemes}
												/>
											</div>
										)}
								</Col>
							</Row>
						</Container>
					</Col>
				</Row>
			</Container>
		</React.Fragment>
	);
};

const mapStateToProps = (state) => ({
	episodeUpdates: state.episode.episodeUpdates,
	episodeResourceCreates: state.episode.episodeResourceCreates,
	episodeResourceUpdates: state.episode.episodeResourceUpdates,
	episodeResourceDeletes: state.episode.episodeResourceDeletes,
	episodeUploads: state.episode.episodeUploads,
	uploadWizardModal: state.ui.modals.uploadWizardModal,
	account: state.accounts,
	permissions: state.auth.permissions,
});

export default connect(mapStateToProps)(EpisodeInfo);
