import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/browser';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

import { unregister as unregisterServiceWorker } from './registerServiceWorker';
import App from 'components/app';
import store, { history } from 'store/store';
import {
	initApolloClient,
	getApolloClient,
} from 'services/graphql/apolloClient';

import './index.scss';

(async () => {
	Sentry.init({
		dsn: 'https://1b7f7e39ca174233a0003d6059f10221@sentry.io/1874225',
		environment: process.env.REACT_APP_RELEASE,
		beforeBreadcrumb(breadcrumb, _hint) {
			if (breadcrumb.category === 'console') {
				return null
			}
			return breadcrumb
		},
	});

	LogRocket.init('pte334/casted', {
		network: {
			requestSanitizer: (request) => {
				// scrub all requests to auth service
				if (request.url.toLowerCase().indexOf('/auth/') !== -1) {
					return null;
				}

				// scrub auth token from request
				if (request.headers['authorization']) {
					request.headers['authorization'] = '';
				}

				return request;
			},
		},
	});

	setupLogRocketReact(LogRocket);

	initApolloClient();
	const ApolloClient = getApolloClient();

	const LDProvider = await asyncWithLDProvider({
		clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
		options: { bootstrap: 'localStorage' },
		deferInitialization: true,
	});

	const container = document.getElementById('root');

	const root = createRoot(container);
	root.render(
		<ApolloProvider client={ApolloClient}>
			<LDProvider>
				<Provider store={store}>
					<Router history={history}>
						<App />
					</Router>
				</Provider>
			</LDProvider>
		</ApolloProvider>
	);

	unregisterServiceWorker();
})();
