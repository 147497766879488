import React, { useState } from 'react';

import API from 'services/api';
import ThumbnailUploader from 'components/shared/thumbnailUploader';
import { FaUpload } from 'react-icons/fa';
import Toast from 'services/toast';

const EpisodeYoutubeThumbnail = (props) => {
	const { 
		thumbnail: _thumbnail, 
		onChange = () => {}, 
		canEdit 
	} = props;

	const [thumbnail, setThumbnail] = useState(_thumbnail);
	const [loading, setIsLoading] = useState(false);

	const onUpload = async (imgDataUrl, fileExt) => {
		setThumbnail(imgDataUrl);
		setIsLoading(true);
		try {
			const gcsUrl = await API.uploadNonSVGPublicFile({
				dataURI: imgDataUrl,
				fileExt,
			});
			setThumbnail(gcsUrl);
			onChange('youtubeThumbnail', gcsUrl);
			setIsLoading(false);
		} catch (e) {
			setIsLoading(false);
			setThumbnail('');
			Toast.error(
				typeof e.message  === 'string' ? e.message : 'Something went wrong!'
			);
			console.error(e);
		}
	};

	const overlay = () => {
		return (
			<div className="thumbnail-upload-overlay h-100 w-100 justify-content-center align-items-center">
				<div className="d-flex flex-column align-items-center">
					<FaUpload size={30} className="mb-4" />
					<>
						Drag and drop or <br />
						Click to update <br />
						thumbnail
					</>
				</div>
			</div>
		);
	};

	return (
		<ThumbnailUploader
			thumbnailImgSrc={thumbnail}
			onUpload={onUpload}
			loading={loading}
			className="hosted"
			overlay={overlay}
			disabled={!canEdit}
		/>
	);
};

export default EpisodeYoutubeThumbnail;
