import React, { useState }  from 'react';
import { connect } from 'react-redux';

import { Row, Col, Alert } from 'react-bootstrap';
import { FaVideo, FaAngleRight } from 'react-icons/fa';
import ConfirmationModal from 'components/shared/confirmationModal';

import Button from 'components/shared/button';

const AudiogramSteps = (props) => {
	const {
		templates,
		createAudiogram,
		selectedTemplate,
		setSelectedTemplate,
		selectedClip, 
		showThumbnailWarning
	} = props;

	const { startTime, endTime } = selectedClip || {};

	const clipLength = endTime - startTime;
	const clipTooLong = clipLength >= 420;

	const [confirmCreate, setConfirmCreate] = useState(false);

	if(confirmCreate) {
		return (
			<ConfirmationModal
				isOpen={confirmCreate}
				title="Confirm Audiogram creation"
				confirmationText="Heads up, you don't have artwork set on the item or collection level which could mess up your gram depending on the template you selected. If this is not desired, please first set a thumbnail. Otherwise click continue to generate the gram."
				confirmationButtonLabel="CONTINUE"
				confirmButtonVariant="success"
				onCancel={() => {
					setConfirmCreate(false);
				}}
				onConfirm={createAudiogram}
			/>
		);
	}

	return (
		<div className="create-audiogram audiogram-templates">
			<div className="preview-instructions">
				<h4 className="preview-title">Shareable Audiogram</h4>
				<p>
					Create a video that can be shared on social and other channels. Keep
					in mind that most social platforms have a limit of 140 seconds for
					videos. Start by choosing a template below:
				</p>
				<p>
					<strong>Note</strong> The video will include subtitles - make sure you
					review the transcript for accuracy.
				</p>
			</div>

			{clipTooLong ? (
				<Alert variant="warning">
					<Alert.Heading>Clip Too Long</Alert.Heading>
					<p>
						The Clip needs to be <strong>less than 7 minutes long</strong> to
						generate an Audiogram.
					</p>
					<hr />
					<p>Try a shorter clip or share this clip as a link.</p>
				</Alert>
			) : (
				<Row>
					{templates.map((template, i) => (
						<Col xs="6" key={template.id}>
							<img
								src={template.thumbnail}
								className={`audiogram-template ${
									selectedTemplate === template.id ? 'selected' : ''
								}`}
								onClick={() => {
									setSelectedTemplate(template.id);
								}}
							/>
							{template.templateMediaType === 'video' && (
								<div class="triangle-badge">
									<FaVideo className="video-icon" />
								</div>
							)}
						</Col>
					))}
				</Row>
			)}

			<div className="audiogram-next-step">
				<Button
					onClick={() => {
						if(showThumbnailWarning) {
							setConfirmCreate(true)
							return;
						}

						createAudiogram();
					}}
					variant="link-no-href"
					disabled={!selectedTemplate}
				>
					Next <FaAngleRight />
				</Button>
			</div>
		</div>
	);

};

const mapStateToProps = (state) => ({
	selectedClip: state.transcript.selectedClip,
});

export default connect(mapStateToProps)(AudiogramSteps);